import {Outlet} from '@remix-run/react';
import {Footer} from '~/components/footer';
import {Header} from '~/components/header';

export default function PublicPage() {
  return (
    <>
      <Header />
      <main className="flex flex-col flex-1 pt-32 px-8 mx-auto w-full max-w-screen-lg">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

import {Link} from '@remix-run/react';

export const Footer = () => {
  return (
    <footer className="w-full p-4 mt-12 bg-background/30">
      <div className="flex sm:flex-row gap-8 flex-col justify-between items-center sm:items-start w-full max-w-screen-lg mx-auto">
        <div className="flex-1" />
        <div className="flex flex-col gap-2 flex-[2] sm:items-start items-center">
          <span className="text-lg font-bold">Site</span>
          <Link to="/" className="text-sm">
            Accueil
          </Link>
          <Link to="/blog" className="text-sm">
            Blog
          </Link>
        </div>
        <div className="flex flex-col gap-2 flex-[2] sm:items-start items-center">
          <span className="text-lg font-bold">Docs</span>
          <Link className="text-sm" to="/docs/legal">
            Mentions légales
          </Link>
          <Link className="text-sm" to="/docs/privacy">
            Politique de confidentialité
          </Link>
        </div>
        <div className="flex flex-col gap-2 flex-[2] sm:items-start items-center">
          <span className="text-lg font-bold">Liens</span>
          <Link className="text-sm" to="https://simonboisset.com/fr">
            Site du développeur
          </Link>
          <Link className="text-sm" to="https://apps.apple.com/app/mon-pont-chaban/id6448217836">
            Application iOS
          </Link>
          <Link className="text-sm" to="https://play.google.com/store/apps/details?id=com.simonboisset.monpontchaban">
            Application Android
          </Link>
        </div>
      </div>
    </footer>
  );
};
